import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import useJwt from "../components/contexts/useJwt.jsx";
import Layout from "../components/Layout.jsx";
import TableLoading from "../components/primitives/TableLoading.jsx";

//---------------------------------------------------------------------------
// Application Pages
//---------------------------------------------------------------------------
const Login = React.lazy(() => import(/* webpackPrefetch: true */ "./Login.jsx"));
const ActivateAccount = React.lazy(() => import(/* webpackPrefetch: true */ "./ActivateAccount.jsx"));
const Dashboard = React.lazy(() => import(/* webpackPrefetch: true */ "./Dashboard/Dashboard.jsx"));
const Devices = React.lazy(() => import(/* webpackPrefetch: true */ "./DevicesPage/DevicesPage.jsx"));
const Terminated = React.lazy(
  () => import(/* webpackPrefetch: true */ "./TerminatedPage/TerminatedPage.jsx")
);
const DeviceTypes = React.lazy(
  () => import(/* webpackPrefetch: true */ "./DeviceTypesPage/DeviceTypesPage.jsx")
);
const DeviceVariants = React.lazy(
  () => import(/* webpackPrefetch: true */ "./DeviceVariantsPage/DeviceVariantsPage.jsx")
);
const FirmwareReleases = React.lazy(
  () => import(/* webpackPrefetch: true */ "./FirmwareReleasesPage/FirmwareReleasesPage.jsx")
);
const BulkDeviceActions = React.lazy(
  () => import(/* webpackPrefetch: true */ "./BulkDeviceActions/BulkDeviceActions.jsx")
);
const TerminateDevices = React.lazy(
  () => import(/* webpackPrefetch: true */ "./TerminateDevicesPage/TerminateDevicesPage.jsx")
);
const Facilities = React.lazy(
  () => import(/* webpackPrefetch: true */ "./FacilitiesPage/FacilitiesPage.jsx")
);
const Users = React.lazy(() => import(/* webpackPrefetch: true */ "./UsersPage/UsersPage.jsx"));
const UsageReport = React.lazy(
  () => import(/* webpackPrefetch: true */ "./UsageReportPage/UsageReportPage.jsx")
);
const ServiceBilling = React.lazy(
  () => import(/* webpackPrefetch: true */ "./ServiceBillingPage/ServiceBillingPage.jsx")
);
const ArrhythmiaSettings = React.lazy(
  () => import(/* webpackPrefetch: true */ "./ArrhythmiaSettingsPage/ArrhythmiaSettingsPage.jsx")
);
const DeviceConfigurations = React.lazy(
  () => import(/* webpackPrefetch: true */ "./DeviceConfigurationsPage/DeviceConfigurationsPage.jsx")
);
const StripClassifications = React.lazy(
  () => import(/* webpackPrefetch: true */ "./StripClassificationsPage/StripClassificationsPage.jsx")
);
const InsuranceTypes = React.lazy(
  () => import(/* webpackPrefetch: true */ "./InsuranceTypesPage/InsuranceTypesPage.jsx")
);
const MessageOptions = React.lazy(
  () => import(/* webpackPrefetch: true */ "./MessageOptionsPage/MessageOptionsPage.jsx")
);
const Workflows = React.lazy(() => import(/* webpackPrefetch: true */ "./WorkflowsPage/WorkflowsPage.jsx"));
const Support = React.lazy(() => import(/* webpackPrefetch: true */ "./SupportPage/SupportPage.jsx"));
const Page404 = React.lazy(() => import(/* webpackPrefetch: true */ "./Page404.jsx"));
const StudyConfigurations = React.lazy(
  () => import(/* webpackPrefetch: true */ "./StudyConfigurationsPage/StudyConfigurationsPage.jsx")
);

const DEFAULT_PAGE = "/devices";
const LOGIN_PAGE = "/login";

function App() {
  //---------------------------------------------------------------------------
  // Redirect to login
  //---------------------------------------------------------------------------
  const {unauthorized} = useJwt();

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <React.Suspense fallback={<TableLoading />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          index
          path="/"
          element={
            <Navigate
              to={unauthorized ? LOGIN_PAGE : DEFAULT_PAGE}
              state={{from: DEFAULT_PAGE, message: null}}
              replace
            />
          }
        />
        <Route
          path="/activateAccount/:pendingUserId"
          element={<ActivateAccount title="Activate Your Account" />}
        />
        <Route path="/resetAccount/:userId/:code" element={<ActivateAccount title="Reset Your Account" />} />
        <Route element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/terminated" element={<Terminated />} />
          <Route path="/device-types" element={<DeviceTypes />} />
          <Route path="/device-variants" element={<DeviceVariants />} />
          <Route path="/firmware-releases" element={<FirmwareReleases />} />
          <Route path="/bulk-device-actions" element={<BulkDeviceActions />} />
          <Route path="/terminate-devices" element={<TerminateDevices />} />
          <Route path="/facilities" element={<Facilities />} />
          <Route path="/users" element={<Users />} />
          <Route path="/usage-report" element={<UsageReport />} />
          <Route path="/service-billing" element={<ServiceBilling />} />
          <Route path="/arrhythmia-settings" element={<ArrhythmiaSettings />} />
          <Route path="/device-configurations" element={<DeviceConfigurations />} />
          <Route path="/strip-classifications" element={<StripClassifications />} />
          <Route path="/insurance-types" element={<InsuranceTypes />} />
          <Route path="/message-options" element={<MessageOptions />} />
          <Route path="/study-configurations" element={<StudyConfigurations />} />
          <Route path="/workflows" element={<Workflows />} />
          <Route path="/support" element={<Support />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </React.Suspense>
  );
}

export default App;
